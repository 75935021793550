html {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: hidden;
}

html::-webkit-scrollbar {
  width: 0px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  border-bottom: solid 1px #EBEBEB;
}

.bottom-tab-nav {
  border-top: solid 1px #EBEBEB;
}

.nav-link,
.bottom-nav-link {
  color: #55575b;
}

.bottom-nav-link.active {
  color: #922c88;
}

.bottom-tab-label {
  font-size: 12px;
}

.efbTitle {
  font-size: large;
  font-weight: bold;
  padding: 16px;
}